import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import style from '../styles/post.module.css'

const Section = ({ title, followText, path }) => (
  <div className={style.section}>
    <h2>{title}</h2>
    <Link to={path}>
      <p className={style.link}>{followText}</p>
    </Link>
  </div>
)

Section.propTypes = {
  title: PropTypes.string,
  followText: PropTypes.string,
  path: PropTypes.string,
}

export default Section
