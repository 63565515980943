import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import style from '../styles/post.module.css'
import { getPostAside } from '../helpers/index'

const IndexPosts = () => {
  const data = useStaticQuery(graphql`
    query IndexPostsQuery {
      allMarkdownRemark(
        filter: {frontmatter: {published: {eq: true}}, fileAbsolutePath: {regex: "//content/journal//"}}
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              date(formatString: "DD MMMM YYYY")
              path
              link
              type
              published
            }
            fields {
              readingTime {
                text
              }
            }
          }
        }
      }
    }
  `)
  const {
    allMarkdownRemark: { edges: posts },
  } = data

  return (
    <div className={style.post}>
      {posts.map(({ node }) => (
        <div key={node.id} className={style.oneLine}>
          <Link to={node.frontmatter.path}>
            <p>{node.frontmatter.title}</p>
          </Link>
          <p className={style.aside}>{getPostAside(node.frontmatter.type, node.fields.readingTime.text)}</p>
        </div>
      ))}
    </div>
  )
};

export default IndexPosts
