import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Intro from '../components/intro'
import Section from '../components/section'
import IndexPosts from '../components/index.posts'
import IndexProjects from '../components/index.projects'

const Index = () => (
  <>
    <SEO />
    <Layout>
      <Intro text="Ruben is a Customer Solutions Engineering Lead @ Asana." />
      <Section title="Latest Posts" followText="Journal" path="/journal" />
      <IndexPosts />
      <Section title="Active Projects" followText="Others" path="/projects" />
      <IndexProjects />
    </Layout>
  </>
)

Index.propTypes = {}

export default Index