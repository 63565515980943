import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import style from "../styles/post.module.css";

const IndexProjects = () => {
  return (
    <div className={style.post}>
      <div className={style.oneLine}>
        <Link to="/wayne">
          <p>Wayne</p>
        </Link>
        <p className={style.aside}>→</p>
      </div>
    </div>
  );
};

export default IndexProjects;
